<template>
  <q-select
    :label="label || undefined"
    v-model="select"
    :options="options"
    :multiple="multiple"
    dense
  />
</template>

<script setup>
import { computed, defineEmits } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: [String, Array],
  multiple: Boolean,
  options: Array,
});

const emits = defineEmits(["updateRowRef"]);

const select = computed({
  get() {
    if (props.multiple && typeof props.value === "string") {
      return [props.value];
    }
    return props.value;
  },
  set(val) {
    emits("updateRowRef", val);
  },
});
</script>
