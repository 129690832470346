<template>
  <p class="q-mt-xs">
    <span class="keys">"{{ label }}"</span>: {
  </p>
  <div>
    <div v-for="(data, key) in value" :key="key">
      <div class="row items-center justify-start q-pl-lg">
        <div v-if="data.input" class="col-6">
          <span class="keys">"{{ key }}"</span>:
        </div>
        <div>
          <div v-if="data.input">
            <Toggle
              v-if="data.input == 'Toggle'"
              :label="''"
              :value="settings[key]"
              v-bind="data.settings"
              @updateRowRef="updateSettings($event, key)"
            />
            <Select
              v-if="data.input == 'Select'"
              :label="''"
              :value="settings[key]"
              v-bind="data.settings"
              @updateRowRef="updateSettings($event, key)"
            />
            <Input
              v-if="data.input == 'Input'"
              :label="''"
              :value="settings[key]"
              v-bind="data.settings"
              @updateRowRef="updateSettings($event, key)"
            />
          </div>
          <div v-if="!data.input">
            <SubComponentObject
              :label="key"
              :value="data"
              :settingsValue="settings[key]"
              @updateComponentSettings="updateSettings($event, key)"
            />
          </div>
        </div>
      </div>
    </div>
    <p>},</p>
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import Toggle from "../Toggle.vue";
import Select from "../Select.vue";
import Input from "../Input.vue";
import SubComponentObject from "./SubComponentObject.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
  settingsValue: Object,
});

// eslint-disable-next-line no-unused-vars
const emits = defineEmits(["updateComponentSettings"]);

const settings = computed({
  get() {
    return props.settingsValue;
  },
  set(val) {
    emits("updateComponentSettings", val);
  },
});

function updateSettings(value, key) {
  const settingsProxy = settings.value;
  settingsProxy[key] = value;
  settings.value = settingsProxy;
}
</script>
