<template>
  <q-toggle
    :label="label || undefined"
    v-model="toggle"
    left-label
    :true-value="trueValue"
    :false-value="falseValue"
  />
</template>

<script setup>
import { computed, defineEmits } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
  trueValue: {
    default: 1,
    type: [Boolean, Number],
  },
  falseValue: {
    default: 0,
    type: [Boolean, Number],
  },
});

const emits = defineEmits(["updateRowRef"]);

const toggle = computed({
  get() {
    return props.value;
  },
  set(val) {
    emits("updateRowRef", val);
  },
});
</script>
