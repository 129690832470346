<template>
  <q-input
    v-if="!number"
    style="min-width: 250px"
    :label="label || undefined"
    v-model="input"
    dense
  />
  <q-input
    v-if="number"
    style="min-width: 250px"
    :label="label || undefined"
    v-model.number="input"
    type="number"
    :rules="id ? idRule : numberRule"
    lazy-rules
    dense
  />
</template>

<script setup>
import { computed, defineEmits } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  label: String,
  value: String,
  number: Boolean,
  id: Boolean,
});

const emits = defineEmits(["updateRowRef"]);

const idRule = [
  (val) => val >= 0 || "The number must be positive",
  (val) => val >= 1 || "Id can't go below 1",
];
const numberRule = [(val) => val >= 0 || "The number must be positive"];

const input = computed({
  get() {
    return props.value;
  },
  set(val) {
    emits("updateRowRef", val);
  },
});
</script>
